<script>
import { unsubscribeAlert } from '@/api'

export default {
  metaInfo() {
    return {
      title: 'Unsubscribe alert - Accounts - Europe PMC',
    }
  },
  props: {
    code: {
      type: String,
      default: '',
    },
    alert: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      clicked: false,
      hasUnsubscribed: false,
    }
  },
  methods: {
    async unsubscribeAlert() {
      try {
        const response = await unsubscribeAlert(this.code)
        if (!response['Error']) {
          this.hasUnsubscribed = true
        }
      } finally {
        this.clicked = true
      }
    },
  },
}
</script>
<template>
  <div id="unsubscribe-alert-page">
    <div class="grid-row">
      <div class="col-16">
        <h1>Europe PMC alerts</h1>
        <template v-if="!clicked">
          <p>
            Saved alert: <b>{{ alert }}</b>
          </p>
          <p>
            Updates sent to: <b>{{ email }}</b>
          </p>
          <div>
            <input
              type="button"
              class="secondary"
              value="Unsubscribe from email updates for this alert"
              @click.prevent="unsubscribeAlert"
            />
          </div>
          <p>
            Login to see all <a href="/accounts/SavedAlerts">Saved alerts</a>.
          </p>
        </template>
        <template v-else>
          <p v-if="hasUnsubscribed">
            You have unsubscribed to the alert for <b>{{ alert }}</b
            >.
          </p>
          <p v-else="">
            This alert unsubscription link is no longer valid. The alert may
            have already been unsubscribed.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#unsubscribe-alert-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
}
</style>
